/*
 * @Author: xutingting tingting.xu@theone.art
 * @Date: 2022-10-27 19:09:45
 * @LastEditors: xutingting tingting.xu@theone.art
 * @LastEditTime: 2022-10-29 16:56:47
 * @FilePath: /theone-chain-explorer-frontend/src/api/login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import request from './request'
/**
 * 获取块列表
 */

export function $userInfo(data) {
    return request({
        url: '/userInfo',
        method: 'post',
        data
    })
}

export function $objectList(data){
        return request({
            url:'/objectList',
            method:'post',
            data
        })
}
