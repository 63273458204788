/*
 * @Author: xutingting tingting.xu@theone.art
 * @Date: 2022-07-11 15:36:39
 * @LastEditors: xutingting tingting.xu@theone.art
 * @LastEditTime: 2022-11-08 14:07:01
 * @FilePath: /theone-chain-explorer-frontend-main/src/api/request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable no-unused-vars */
/**
 * 封装Axios
 * 处理请求、响应错误信息
 */

import axios from 'axios' // 引用axios

const getToken = () => {
  const token = localStorage.getItem('TOKEN')
  return `Bearer ${token}`
}
// create an axios instance
const service = axios.create({
  baseURL: '/api',
  // baseURL: process.env.VUE_APP_BASE,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  headers: {
    authorization: getToken(),
    common: {
      authorization: getToken()
    }
  }
})


// request interceptor
service.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers.authorization = token
      return config
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  response => {
    // res is my own data
    return {
      ...response.data,
      code: response.data.code == 0 ? 200 : response.data.code
    }

   
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        // 服务器状态
        case 401:
          window.location.href = '/chain'
          break
        default:
          return {
            code: error.response.status,
            msg: error.response.statusText
          }
      }
    }
  }
)
const request = (params) => {
  return service(params).catch((info) => {
    return info
  })
}

export default service // 导出封装后的axios
