/*
 * @Author: nora tingting.xu@theone.art
 * @Date: 2022-11-14 18:36:05
 * @LastEditors: nora tingting.xu@theone.art
 * @LastEditTime: 2022-11-24 22:46:45
 * @FilePath: \theone-home\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {
  createStore
} from 'vuex'

const store = createStore({
  state() {
      return {
          isShowPhoneNav: false,
          page:1,
          contract:'',
          info:[]
      }
  },
  mutations: {
      increment(state) {
          state.count++
      },
      setPhoneNav(state, isShow) {
          state.isShowPhoneNav = isShow;
      },
      changePage(state,data) {
       state.page = data;
      },
      changeContract(state,payload){
          state.contract = payload
      },
      infoList(state,data){
          state.info = data
          console.log("data",state.info )
      }

  },
})
export default store;


