/*
 * @Author: xutingting tingting.xu@theone.art
 * @Date: 2022-09-20 19:02:07
 * @LastEditors: xutingting tingting.xu@theone.art
 * @LastEditTime: 2022-12-08 19:35:58
 * @FilePath: /theone-chain-explorer-frontend/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '../src/assets/style/theme/themes.scss'
// import Antd from 'ant-design-vue'
import {Avatar,Button,Tooltip,Pagination,Input,Table,ConfigProvider,Skeleton,Statistic,Tabs,Image,Badge,Collapse,CollapsePanel,Carousel} from 'ant-design-vue'

import 'ant-design-vue/dist/antd.css'
import '../src/assets/font/font.css'
require('./utils/iconfont');


createApp(App).use(Avatar).use(Button).use(Tooltip).use(Pagination).use(Input).use(Table)
.use(ConfigProvider).use(Skeleton).use(Statistic).use(Tabs).use(Image).use(Badge).use(Collapse).use(CollapsePanel).use(Carousel).use(store).use(router).mount('#app')
