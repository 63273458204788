/*
 * @Author: xutingting tingting.xu@theone.art
 * @Date: 2022-10-27 19:49:43
 * @LastEditors: nora tingting.xu@theone.art
 * @LastEditTime: 2022-11-23 17:53:26
 * @FilePath: /theone-chain-explorer-frontend/src/utils/author.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const TOKEN = 'TOKEN'
const USERINFO = 'USERINFO' 
const SHOWIDENTITY = 'SHOWIDENTITY'
/**
保存用户token信息
*/
export function setToken(token){
if(token){
			window.localStorage.setItem(TOKEN,token)
	}
}

export function getToken(){
	const token =window.localStorage.getItem(TOKEN)
	return token
}

export function removeToken(){
			window.localStorage.removeItem(TOKEN)
}


export function saveUserInfo(userinfo){
if(userinfo){
		window.localStorage.setItem(USERINFO,JSON.stringify(userinfo))
	}
}
export function getUserInfo(){
const strUserInfo = window.localStorage.getItem(USERINFO)
if(strUserinfo){
		return JSON.parse(strUserinfo)
	}
}
export function removeUserInfo(){
		window.localStorage.removeItem(USERINFO)
}


export function setIdentity(showIdentity){
	if(showIdentity){
				window.localStorage.setItem(SHOWIDENTITY,showIdentity)
		}
	}
	
	export function getIdentity(){
		const showIdentity =window.localStorage.getItem(SHOWIDENTITY)
		return showIdentity
	}
	
	export function removeIdentity(){
				window.localStorage.removeItem(SHOWIDENTITY)
	}