<!--
 * @Author: xutingting tingting.xu@theone.art
 * @Date: 2022-09-20 19:02:07
 * @LastEditors: nora tingting.xu@theone.art
 * @LastEditTime: 2022-12-01 23:19:45
 * @FilePath: /theone-chain/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- <router-view/> -->
  <Header></Header>
   <a-config-provider :locale="locale">
  <router-view :key="key"></router-view>
 </a-config-provider>
</template>
<script setup>
import Header from '../src/components/global/Header.vue'
import zhCN from "ant-design-vue/es/locale/zh_CN";
import {ref, computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const key = computed(() => {
  // return route.path + Math.random()
  
   return route.name?route.name +new Date():route+ +new Date()
})
const locale = ref(zhCN)
</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
}
</style>
